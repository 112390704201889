const chroma = require('chroma-js')
const sample = require('lodash.sample')
const { randomInt } = require('../util')
const generate = require('./colorbox').default

const curves = [
  'easeInQuad',
  'easeOutQuad',
  'easeInOutQuad',
  'easeInQuart',
  'easeOutQuart',
  'easeInOutQuart',
  'easeInSine',
  'easeOutSine',
  'easeInOutSine',
  'easeInCubic',
  'easeOutCubic',
  'easeInOutCubic',
  'easeInExpo',
  'easeOutExpo',
  'easeInOutExpo',
  'easeInQuint',
  'easeOutQuint',
  'easeInOutQuint',
  'easeInCirc',
  'easeOutCirc',
  'easeInOutCirc',
  'easeInBack',
  'easeOutBack',
  'easeInOutBack',
  'linear'
]

const curvesIn = [
  'easeInQuad',
  'easeInQuart',
  'easeInSine',
  'easeInCubic',
  'easeInExpo',
  'easeInQuint',
  'easeInCirc',
  'easeInBack',
  'linear'
]

const curvesOut = [
  'easeOutQuad',
  'easeOutQuart',
  'easeOutSine',
  'easeOutCubic',
  'easeOutExpo',
  'easeOutQuint',
  'easeOutCirc',
  'easeOutBack',
  'linear'
]

const generateComponent = color => {
  const steps = sample([3, 4, 5, 6, 7, 8, 9, 10, 11])
  const hue_curve = sample(curves)
  const sat_curve = sample(curves)
  const lum_curve = sample(curves)
  const sat_rate = randomInt(130, 200)

  const input = {
    specs: {
      // Number of colors
      steps: steps,
      // Hue Start Value (0 - 359)
      hue_start: randomInt(293, 315),
      // Hue End Value (0 - 359)
      hue_end: randomInt(293, 315),
      // Hue Curve (See Curves Section)
      hue_curve: hue_curve,
      // Saturation Start Value (0 - 100)
      sat_start: 4,
      // Saturation End Value (0 - 100)
      sat_end: 90,
      // Saturation Curve (See Curves Section)
      sat_curve: sat_curve,
      // Saturation Rate (0 - 200)
      sat_rate: sat_rate,
      // Luminosity Start Value (0 - 100)
      lum_start: randomInt(70, 100),
      // Luminosity End Value (0 - 100)
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      // Modifier Scale
      // Every generated color gets a modifier (label) that
      // indicates its lightness. A value of 10 results in
      // two-digit modifiers. The lightest color will be 0 (e.g. Red 0)
      // and the darkest color will be 100 (e.g. Red 100), given
      // that you generate 11 colors
      modifier: 10
    }
  }

  const redInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(345, 360),
      hue_end: randomInt(345, 360),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 32),
      sat_end: randomInt(48, 100),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(70, 100),
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const goldInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(12, 60),
      hue_end: randomInt(12, 60),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 32),
      sat_end: randomInt(48, 100),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(70, 100),
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const cyanInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(140, 180),
      hue_end: randomInt(140, 180),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 32),
      sat_end: randomInt(48, 100),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(70, 100),
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const blueInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(180, 220),
      hue_end: randomInt(180, 220),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 32),
      sat_end: randomInt(48, 100),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(70, 100),
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const indigoInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(240, 260),
      hue_end: randomInt(240, 260),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 32),
      sat_end: randomInt(48, 100),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(70, 100),
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const greenInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(60, 140),
      hue_end: randomInt(60, 140),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 32),
      sat_end: randomInt(48, 100),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(70, 100),
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const violetInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(260, 320),
      hue_end: randomInt(260, 320),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 32),
      sat_end: randomInt(48, 100),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(70, 100),
      lum_end: randomInt(0, 50),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const grayInput = {
    specs: {
      steps: steps,
      hue_start: randomInt(190, 260),
      hue_end: randomInt(190, 260),
      hue_curve: hue_curve,
      sat_start: randomInt(0, 4),
      sat_end: randomInt(0, 8),
      sat_curve: sat_curve,
      sat_rate: sat_rate,
      lum_start: randomInt(90, 100),
      lum_end: randomInt(0, 16),
      lum_curve: lum_curve,
      modifier: 10
    }
  }

  const red = generate(redInput)
  const gold = generate(goldInput)
  const cyan = generate(cyanInput)
  const blue = generate(blueInput)
  const indigo = generate(indigoInput)
  const violet = generate(violetInput)
  const green = generate(greenInput)
  const gray = generate(grayInput)

  const div = {
    red: red,
    gold: gold,
    green: green,
    cyan: cyan,
    blue: blue,
    indigo: indigo,
    violet: violet,
    gray: gray
  }

  return {
    ...div
  }
}

module.exports = generateComponent
