import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Grid, ArrowRight, Heart, BookOpen } from 'react-feather'

import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import H5 from '../elements/H5'
import Textarea from '../elements/Textarea'
import Input from '../elements/Input'
import Span from '../elements/Span'
import Flex from '../components/Flex'
import SectionBorder from '../components/SectionBorder'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import Color from '../components/controls/Color'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomComponent from '../lib/generate/color-palette'
import { db } from '../lib/client'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const Main = ({ div }) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '16rem',
      height: '100%',
      maxHeight: '100vh',
      backgroundColor: div.parentBg,
      flexWrap: 'wrap'
    }}
  >
    <section
      css={{ fontSize: 12, width: '100%', paddingLeft: 64, paddingRight: 64 }}
    >
      <table
        cellSpacing="0"
        cellPadding="0"
        borderCollapse="collapse"
        css={{ width: '100%' }}
      >
        <tr>
          {div.gray.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          {div.red.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          {div.gold.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          {div.green.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          {div.cyan.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          {div.blue.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          {div.indigo.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
        <tr>
          {div.violet.map(color => (
            <td>
              <div
                css={{
                  paddingLeft: 16,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16,
                  background: color.hex
                }}
              >
                <div
                  css={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 4
                  }}
                >
                  <div
                    css={{ fontWeight: 700, color: 'black', marginRight: 8 }}
                  >
                    {color.contrastBlack}
                  </div>
                  <div
                    css={{
                      fontWeight: 700,
                      marginLeft: 'auto',
                      color: 'white'
                    }}
                  >
                    {color.contrastWhite}
                  </div>
                </div>
                <code css={{ fontSize: 14, color: color.displayColor }}>
                  {color.hex}
                </code>
              </div>
            </td>
          ))}
        </tr>
      </table>
    </section>
  </div>
)

const GridItem = ({ div, ...props }) => (
  <div
    css={{
      width: '25%',
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '16px',
      height: '23vh'
    }}
    {...props}
  >
    <a
      css={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <section>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.gray.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.red.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.gold.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.green.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.cyan.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.blue.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.indigo.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
        <div css={{ width: '100%', display: 'flex' }}>
          {div.violet.map(color => (
            <div css={{ padding: 8, background: color.hex }}></div>
          ))}
        </div>
      </section>
    </a>
  </div>
)

const GridView = ({ pins, onSelect }) => (
  <Flex
    css={{
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      paddingTop: '32px',
      paddingBottom: '32px',
      paddingLeft: '8px',
      paddingRight: '8px'
    }}
  >
    {Array(16)
      .fill(0)
      .map((_, i) => {
        const baseDiv = randomComponent()
        const divWithPins = { ...baseDiv, ...pins }
        const div = {
          ...divWithPins
        }

        return <GridItem key={i} onClick={() => onSelect(div)} div={div} />
      })}
  </Flex>
)

const Sidebar = ({ onChange, value, pins, onPin }) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  const grayCssVars = value.gray
    .map((color, i) => {
      return `--gray-${i * 1}: ${color.hex};\n`
    })
    .join('')
  const redCssVars = value.red
    .map((color, i) => {
      return `--red-${i * 1}: ${color.hex};\n`
    })
    .join('')
  const goldCssVars = value.red
    .map((color, i) => {
      return `--gold-${i * 1}: ${color.hex};\n`
    })
    .join('')
  const greenCssVars = value.red
    .map((color, i) => {
      return `--green-${i * 1}: ${color.hex};\n`
    })
    .join('')
  const tealCssVars = value.cyan
    .map((color, i) => {
      return `--teal-${i * 1}: ${color.hex};\n`
    })
    .join('')
  const blueCssVars = value.cyan
    .map((color, i) => {
      return `--blue-${i * 1}: ${color.hex};\n`
    })
    .join('')
  const indigoCssVars = value.cyan
    .map((color, i) => {
      return `--indigo-${i * 1}: ${color.hex};\n`
    })
    .join('')
  const violetCssVars = value.cyan
    .map((color, i) => {
      return `--violet-${i * 1}: ${color.hex};\n`
    })
    .join('')

  return (
    <>
      <SidebarHeading>Color Palette</SidebarHeading>
      <SectionBorder my={4} />
      <Div>
        <H5 mb={1}>Css</H5>
        <Textarea
          bg="transparent"
          height={256}
          width={1}
          border="1px solid rgba(0,0,0,.25)"
          p={2}
          readOnly
          value={
            grayCssVars +
            '\n' +
            redCssVars +
            '\n' +
            goldCssVars +
            '\n' +
            greenCssVars +
            '\n' +
            tealCssVars +
            '\n' +
            blueCssVars +
            '\n' +
            indigoCssVars +
            '\n' +
            violetCssVars +
            '\n'
          }
        />
        <H5 mb={1} display="none">
          Js
        </H5>
        <Textarea
          bg="transparent"
          height={128}
          width={1}
          border="1px solid rgba(0,0,0,.25)"
          p={2}
          readOnly
          display="none"
          value={''}
        />
      </Div>
    </>
  )
}

export default ({ initialDiv, initialPins }) => {
  let votes = []
  try {
    votes = JSON.parse(localStorage.getItem('textImageVotes')) || []
  } catch (e) {}

  const [div, setDiv] = useState(initialDiv || randomComponent())
  const [pins, setPins] = useState(initialPins || {})
  const [gridView, setGridView] = useState(false)

  const divWithPins = { ...div, ...pins }
  const fullDiv = {
    ...divWithPins
  }

  const upvote = async () => {
    if (gridView) {
      return
    }

    const { id } = await db
      .collection('components')
      .doc('textImages')
      .collection('votes')
      .add({
        up: true,
        data: fullDiv,
        metadata: { pins }
      })

    localStorage.setItem(
      'textImagesVotes',
      JSON.stringify(
        [
          {
            id
            //backgroundColor: fullGradient.backgroundColor,
            //backgroundImage: fullGradient.backgroundImage
          },
          ...votes
        ]
          .slice(0, 100)
          .filter(Boolean)
      )
    )

    setDiv(randomComponent())
  }
  const skip = () => {
    setDiv(randomComponent())
  }
  const toggleGridView = () => {
    setGridView(!gridView)
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  const handleGridSelection = div => {
    setDiv(div)
    setGridView(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        gridView ? (
          <GridView pins={pins} onSelect={handleGridSelection} />
        ) : (
          <Main div={fullDiv} />
        )
      }
      sidebar={
        <Sidebar
          value={fullDiv}
          pins={pins}
          onChange={(key, value) => {
            setDiv({
              ...div,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <div
            css={{
              color: 'black',
              textAlign: 'center',
              //padding: 20,
              display: 'flex',
              width: '100%',
              alignItems: 'center'
              //borderBottom: '1px solid rgba(0,0,0,.1)'
            }}
          >
            {false && (
              <Link
                to="/div/votes"
                css={{
                  color: 'black',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textDecoration: 'none'
                }}
              >
                <BookOpen size={20} />
                <span css={{ paddingLeft: 10 }}>Previous likes</span>
              </Link>
            )}
          </div>
          <Flex>
            <VoteButton title="Next" onClick={skip} width="50%">
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
            <VoteButton title="Grid view" onClick={toggleGridView} width="50%">
              <Grid size={20} />
              <Hidden>Grid view</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
